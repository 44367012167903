import { useEffect, useState, useContext } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams, useLocation } from 'react-router-dom';
import { UserContext } from "context/user";

// Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { Card, Icon, Tabs, Tab, CircularProgress } from "@mui/material";

// Data
import { MessageManager } from "context";
import { useNavigate } from "react-router-dom";
import Cotizacion from "../Cotizacion";
import Fases from "../Fases";
import ProyectoDetalles from "../ProyectoDetalles";
import Medicaciones from "../Medicaciones";
import EventosAdversos from "../EventosAdversos";
import ModalMensajeFinal from "../ModalMensajeFinal";
import PdfModal from "../PDF/PdfModal";
import PdfFormularioRC from "../PDF/PdfFormularioRC";

const GET_FRC_FORM = gql`
  query getFrcForm($id: ID!) {
    getFrcForm(id: $id) {
      id
      profesional
      MP
      propietario
      contacto
      idProvincia
      idLocalidad
      nombre
      pelaje
      idEspecie
      sexo
      idRaza
      edad
      observaciones

      ecto_nroPulgas
      ecto_nroGarrapatas
      ecto_intensidadPulgas
      ecto_intensidadGarrapatas

      endo_idEspecie
      endo_hpg
      endo_formulacionRecibida
      endo_fechaTratamiento
      endo_fechaTratamiento1
      endo_fechaTratamiento2
      endo_prueba
      endo_referencia
      endo_tipo
      endo_dosis

      eog_peso
      eog_mucosas
      eog_linfodulos
      eog_temperatura
      eog_sensorio
      eog_frecuenciaRespiratoria
      eog_frecuenciaCardiaca
      eog_hidratacion
      eog_consumo
      eog_celo
      eog_fechaInicio
      eog_fechaAlta
      eog_obs

      mr_medicacionRecibida
      mr_antecedentes
      mr_signoDermatitis

      rea_posibleRelacion
      rea_exclusion
      rea_trataConcomitante
      rea_altaClinica
      rea_observaciones

      proyectoId

      documentos {
        id
        file
      }

      localidad {
        id
        nombre
      }

      eventosAdversos {
        id
        fecha
        descripcion
        severidad
        accionTomada
        notificador
        relacionTratamiento
        createdAt
      }

      convivientes {
        id
        nombre
        sexo
        edad
        raza
        peso
        volumen
        observaciones
        tipo
        createdAt
      }
    }
  }
`;


const GET_PROYECTO = gql`
  query getProyecto($id: ID!) {
    proyecto(id: $id) {
      id
      nombre
      
    }
  }
`;

const SAVE_FRC_FORM = gql`
  mutation saveFrcForm($input: FrcFormInput!) {
    saveFrcForm(input: $input) {
      id
    }
  }
`;

const GET_FOTOS = gql`
  query GetFotos($id: ID!) {
    getFrcForm(id: $id) {
      documentos {
        id
        file
      }
    }
  }
`;

const ADD_FRC_FORM_FILE = gql`
  mutation addFrcFormFile($input: FrcFormFileInput!) {
    addFrcFormFile(input: $input) {
      id
      nombre
      file
      createdAt
    }
  }
`;
const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      id
      MP
    }
  }
`;

const FormFrc = () => {
  const location = useLocation();
  const id = location?.state?.id || 0;
  const proyectoId = location?.state?.proyectoId || 0; // Si 'id' está en el estado, lo usa; de lo contrario, asigna 0

  // es una edición o creación?
  const isNew = id === 0;  // Si el 'id' es 0, es un nuevo formulario

  console.log("Es nuevo formulario:", isNew);  // true si es nuevo, false si es edición
  console.log("ID del proyecto:", proyectoId);  // ID del proyecto
  console.log("ID del formulario:", id);  // ID del formulario
  const [proyecto, setProyecto] = useState({});
  const [step, setStep] = useState(0);
  const [loadingSaveAll, setLoadingSaveAll] = useState(false);
  const [modalMensajeFinal, setModalMensajeFinal] = useState(false);
  const [textoFinal, setTextoFinal] = useState({ titulo: "", descripcion: "" });
  const navigate = useNavigate();
  const { handleSnackbar } = useContext(MessageManager);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [fotos, setFotos] = useState([]);
  const [addFrcFormFile] = useMutation(ADD_FRC_FORM_FILE);
  const { user } = useContext(UserContext);
  const [formData, setFormdata] = useState(null);

  const { data: currentUserData, loading: currentUserLoading, error } = useQuery(GET_CURRENT_USER, {
    skip: !user,
  });

  // if (currentUserLoading) return <p>Cargando...</p>;
  // if (error) return <p>Error: {error.message}</p>;


  // Acceder al MP del usuario actual
  const MP = currentUserData?.currentUser?.MP;

  const steps = [
    { title: "Inicio", icon: "pets" },
    { title: "Datos Parásitos", icon: "bug_report" },
    { title: "Examen General", icon: "rule" },
    { title: "Medicaciones y Convivientes", icon: "medications" },
    { title: "Registro de Eventos", icon: "event_note" },
  ];

  const { data: proyectoData, loading } = useQuery(GET_PROYECTO, {
    variables: { proyectoId },
    skip: !proyectoId,
  });

  const { data: frcFormData, loading: frcFormLoading } = useQuery(GET_FRC_FORM, {
    variables: { id },
    skip: !id,
  });

  useEffect(() => {
    if (proyectoData?.proyecto) {
      setProyecto(proyectoData.proyecto);
    }
  }, [proyectoData]);

  useEffect(() => {
    if (frcFormData?.getFrcForm) {
      setProyecto(frcFormData.getFrcForm);
    }
  }, [frcFormData]);


  const { data: fotosData, loading: loadingFotos } = useQuery(GET_FOTOS, {
    variables: { id },
  });
  useEffect(() => {
    if (fotosData?.getFrcForm?.documentos) {
      setFotos(fotosData.getFrcForm.documentos.filter(foto => !foto.deletedAt));  // Guardar las fotos obtenidas
    }
  }, [fotosData, loadingFotos])

  const [saveFrcForm] = useMutation(SAVE_FRC_FORM);


  const generatePdf = (proyectoData) => {
    const fotosFiltradas = fotos.filter(foto => foto.idFrcForm === idFrcForm);
    // Propiedades a pasar al PDF
    const pdfData = {

      //idFormulario: proyectoData.id,
      proyectoId: proyectoData.proyectoId,
      nombre: proyectoData.nombre,
      propietario: proyectoData.propietario || 'N/A',
      MP: proyectoData.MP || 'N/A',
      contacto: proyectoData.contacto || 'N/A',
      provincia: proyectoData.provincia || 'N/A',
      localidad: proyectoData.localidad || 'N/A',
      especie: proyectoData.idEspecie || 'N/A',
      raza: proyectoData.idRaza || 'N/A',
      pelaje: proyectoData.pelaje || 'N/A',
      sexo: proyectoData.sexo || 'N/A',
      observaciones: proyectoData.observaciones || 'N/A',
      fotos: fotosFiltradas,
    };

    const pdfGenerator = PdfFormularioRC({ data: proyectoData });
    const pdfGeneratedUrl = pdfGenerator.generatePdf();
    if (!pdfGeneratedUrl) {
      handleSnackbar("Error al generar el PDF", "error");
      return;
    }
    setPdfUrl(pdfGeneratedUrl);
    setOpenPdfModal(true);
  };

  const downloadPdf = async (pdfUrl) => {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();
    return new File([blob], "formulario.pdf", { type: "application/pdf" });
  };

  const handleFinalSave = async () => {
    setLoadingSaveAll(true);
    try {
      // Preparar datos
      const { provincia, localidad, documentos, ...resto } = proyecto;
      const input = {
        ...resto,
        id: Number(resto.id),
        proyectoId: isNew ? parseInt(proyectoId, 10) : Number(resto.proyectoId),
        MP: parseInt(proyecto.MP, 10),
        mr_antecedentes: Boolean(proyecto.mr_antecedentes),
        rea_posibleRelacion: Boolean(proyecto.rea_posibleRelacion),
        rea_exclusion: Boolean(proyecto.rea_exclusion),
        idProvincia: provincia ? Number(provincia) : null,
        idLocalidad: localidad ? Number(localidad) : null,
        eventosAdversos: resto.eventosAdversos || [],
        convivientes: resto.convivientes || [],
      };
  
      // Eliminar datos innecesarios
      delete input.__typename;
  
      // Guardar el formulario
      const { data } = await saveFrcForm({ variables: { input } });
  
      // Verificar si el guardado fue exitoso
      if (data && data.saveFrcForm) {
        // Solo muestra el mensaje de éxito cuando realmente se guardó
        if (step === steps.length - 1) {
          setModalMensajeFinal(true);
          setTextoFinal({ titulo: "Éxito", descripcion: "Los datos se han guardado correctamente." });
          navigate("/proyectos");
        } else {
          // Avanza al siguiente paso si no es el último paso
          handleNextStep();
        }
  
        // Generar y guardar el PDF (si es necesario)
        const pdfData = {
          proyectoId: proyecto.proyectoId,
          nombre: proyecto.nombre,
          propietario: proyecto.propietario || 'N/A',
          MP: proyecto.MP || 'N/A',
          contacto: proyecto.contacto || 'N/A',
          provincia: proyecto.provincia || 'N/A',
          localidad: proyecto.localidad || 'N/A',
          especie: proyecto.idEspecie || 'N/A',
          raza: proyecto.idRaza || 'N/A',
          pelaje: proyecto.pelaje || 'N/A',
          sexo: proyecto.sexo || 'N/A',
          observaciones: proyecto.observaciones || 'N/A',
          fotos: fotos,
        };
  
        const pdfGenerator = PdfFormularioRC({ data: pdfData });
        const pdfGeneratedUrl = pdfGenerator.generatePdf();
  
        if (pdfGeneratedUrl) {
          const pdfFile = await downloadPdf(pdfGeneratedUrl);
          const fileInput = {
            file: [pdfFile],
            idFrcForm: data.saveFrcForm.id,
          };
          await addFrcFormFile({ variables: { input: fileInput } });
  
          handleSnackbar("Formulario guardado correctamente, PDF agregado", "success");
        } else {
          handleSnackbar("Error al generar el PDF", "error");
        }
      } else {
        // Si no se guardó correctamente
        handleSnackbar("Error al guardar el formulario", "error");
      }
    } catch (error) {
      console.error("Error al guardar el formulario:", error);
      handleSnackbar("Error al guardar el formulario", "error");
    } finally {
      setLoadingSaveAll(false);
    }
  };
  

  const handleOpenPdf = () => {
    generatePdf(proyecto);
  };

  const handleCloseModal = () => {
    setOpenPdfModal(false);
    setPdfUrl(null);
  };


  const validateStep = () => {
    switch (step) {
      case 0:
        if (!proyecto.profesional || !proyecto.propietario || !proyecto.nombre || !proyecto.provincia || !proyecto.localidad || !proyecto.pelaje || !proyecto.sexo || !proyecto.idEspecie || !proyecto.idRaza) {
          handleSnackbar("Por favor complete todos los campos obligatorios", "error");

        }
        break;
      case 1:
        if (!proyecto.endo_idEspecie || !proyecto.endo_formulacionRecibida || !proyecto.endo_fechaTratamiento) {
          handleSnackbar("Las Por favor complete todos los campos obligatorios", "error");

        }
        break;
      case 2:
        if (!proyecto.eog_peso || !proyecto.eog_temperatura || !proyecto.eog_frecuenciaRespiratoria || !proyecto.eog_frecuenciaCardiaca
          || !proyecto.eog_hidratacion || !proyecto.eog_celo || !proyecto.eog_fechaInicio
        ) {
          handleSnackbar("Por favor complete los campos obligatorios", "error");

        }
        break;


      default:
        break;
    }
    return true;
  };

  const handlePreviousStep = () => {
    setStep(prevStep => Math.max(prevStep - 1, 0));
  };
  const handleNextStep = () => {
    if (validateStep()) {
      setStep(step + 1); //Avanza si los campos obligatorios estan completos
    }
  };

  if (currentUserLoading) return <p>Cargando...</p>;

  if (error) return <p>Error: {error.message}</p>;
  if (loading) return <CircularProgress />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
              <SoftBox pb={3}>
                {step === 0 && <ProyectoDetalles proyecto={proyecto} setProyecto={setProyecto} MP={MP} />}
                {step === 1 && <Fases proyecto={proyecto} setProyecto={setProyecto} />}
                {step === 2 && <Cotizacion proyecto={proyecto} setProyecto={setProyecto} />}
                {step === 3 && <Medicaciones proyecto={proyecto} setProyecto={setProyecto} />}
                {step === 4 && <EventosAdversos proyecto={proyecto} setProyecto={setProyecto} />}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between" mt={2}>
                <SoftBox sx={{ display: "flex" }}>
                  <Tabs value={step} TabIndicatorProps={{ style: { display: "none" } }} disabled>
                    {steps.map((thisStep, index) => (
                      <Tab
                        sx={{ px: 2 }}
                        key={index}
                        icon={<Icon>{thisStep.icon}</Icon>}
                        label={thisStep.title}
                      />
                    ))}
                  </Tabs>
                </SoftBox>
                <SoftBox display="flex" alignItems="center">
                  {step > 0 && (
                    <SoftButton
                      variant="outlined"
                      color="primary"
                      onClick={handlePreviousStep}
                      style={{ marginRight: "8px" }}
                    >
                      Anterior
                    </SoftButton>
                  )}
                  {step === steps.length - 1 && (
                    <SoftButton variant="outlined" color="primary" onClick={handleOpenPdf} style={{ marginRight: "8px" }}>
                      Ver PDF
                    </SoftButton>
                  )}
                  {step < steps.length - 1 && (
                    <SoftButton variant="gradient" color="primary" onClick={handleNextStep} disabled={loadingSaveAll} style={{ marginLeft: '8px' }}>
                      Siguiente
                    </SoftButton>
                  )}
                  {step === step && (
                    <SoftButton variant="gradient" color="success" onClick={handleFinalSave} disabled={loadingSaveAll} style={{ marginLeft: '8px' }}>
                      Guardar {loadingSaveAll && <CircularProgress size={15} />}
                    </SoftButton>
                  )}
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
        {/* <ModalMensajeFinal
          open={modalMensajeFinal}
          handleClose={() => setModalMensajeFinal(false)}
          titulo={textoFinal.titulo}
          descripcion={textoFinal.descripcion}
        /> */}
        <PdfModal
          open={openPdfModal}
          handleClose={handleCloseModal}
          pdfUrl={pdfUrl}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FormFrc;
