import { useEffect, useState, useContext } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useNavigate, useParams } from 'react-router-dom';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { Card, Grid, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";
import Loading from "components/Carteles/Loading";
import DataTableFrc from "components/Tables/DataTableFrc";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import { MessageManager } from "context";
import { UserContext } from "context/user";

// Consultas y mutaciones GraphQL
const GET_FORMULARIOS = gql`
  query getFrcForm($proyectoId: ID!, $search: String, $page: Int) {
    paginationInfo {
      pages
      total
    }
    frcForms(proyectoId: $proyectoId, search: $search, pagination: { page: $page, limit: 10 }) {
      id
      proyectoId
      profesional
      contacto
      eog_fechaInicio
      eog_fechaAlta
      documentos {
        id
        nombre
        file
        createdAt
      }
    }
  }
`;

const DELETE_FORMULARIO = gql`
  mutation deleteFrcForm($id: ID!) {
    deleteFrcForm(id: $id) 
  }
`;

function FormulariosFRC() {
  const { proyectoId } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({ pages: 0, total: 0 });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [formularioToDelete, setFormularioToDelete] = useState(null);
  const { handleSnackbar } = useContext(MessageManager);

  // Mutación para eliminar un formulario
  const [deleteFormulario] = useMutation(DELETE_FORMULARIO);

  // Consultas GraphQL
  const { loading, data, refetch } = useQuery(GET_FORMULARIOS, {
    variables: {
      proyectoId,
      search: search || null,
      page: page,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data.frcForms) {
      setRows(data.frcForms.map(row => ({
        ...row,
        id: row.id,
        profesional: row.profesional || "",
        contacto: row.contacto || "",
        eog_fechaInicio: row.eog_fechaInicio || "",
        eog_fechaAlta: row.eog_fechaAlta || "",
      })));
      setPagesInfo(data.paginationInfo);
    } else {
      setRows([]);
      setPagesInfo({ pages: 0, total: 0 });
    }
  }, [data]);

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const handleOpenModalDelete = (id) => {
    setFormularioToDelete(id);
    setOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
    setFormularioToDelete(null); // Limpiar el formulario seleccionado
  };

  const handleConfirmDelete = async () => {
    if (!formularioToDelete) return;
    try {
      const { data } = await deleteFormulario({ variables: { id: formularioToDelete } });
      if (data.deleteFrcForm) {
        handleSnackbar("Formulario eliminado correctamente", "success");
        refetch(); // Recargar los datos después de la eliminación
        handleCloseModalDelete(); // Cerrar el modal
      } else {
        handleSnackbar("Error al eliminar formulario", "error");
      }
    } catch (error) {
      handleSnackbar("Error al eliminar formulario", "error");
      handleCloseModalDelete();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox display="flex" justifyContent="flex-end" py={3}>
          <Card style={{ width: "100%" }}>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftTypography variant="h6">
                    FRC - Proyecto ID: {proyectoId}
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox>
              {loading ? (
                <Loading />
              ) : rows.length > 0 ? (
                <DataTableFrc
                  columns={[
                    { id: "id", label: "ID" },
                    { id: "profesional", label: "Profesional" },
                    { id: "contacto", label: "Contacto" },
                    { id: "eog_fechaInicio", label: "Fecha de Inicio" },
                    { id: "eog_fechaAlta", label: "Fecha de Alta" },
                  ]}
                  rows={rows}
                  refetch={refetch}
                  onDelete={handleOpenModalDelete} // Pasa la función aquí
                />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
            />
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
      {/* Modal de Confirmación */}
      <Dialog
        open={openModalDelete}
        onClose={handleCloseModalDelete}
      >
        <DialogTitle>Confirmación de Eliminación</DialogTitle>
        <SoftBox px={3}>
          <SoftTypography variant="body1" color="secondary">
            ¿Estás seguro de que deseas eliminar este formulario?
          </SoftTypography>
        </SoftBox>
        <DialogActions>
          <SoftButton onClick={handleCloseModalDelete}  style={{ cursor: 'pointer', backgroundColor: '#f44336', color: 'white' }}>
            Cancelar
          </SoftButton>
          <SoftButton onClick={handleConfirmDelete}  style={{ cursor: 'pointer', backgroundColor: '#66BB6A', color: 'white' }}
            >
            Eliminar
          </SoftButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default FormulariosFRC;
