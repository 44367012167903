import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import axios from "axios";

const sha512 = require("js-sha512");

export const enableLog = false;
export const enableConsole = true;
export const puestosConAcceso = [
  "0",
  "1",
  "3",
];
export const adminKey = sha512("al3d3sm4");

export const getVersion = async () => {
  let respuesta = {};

  // await axios
  //   .post("https://tickets.esamba.online/sistema-tickets-api/public/api/tareasUltimaVersion", {
  //     idProyecto: "13",
  //   })
  //   .then((response) => {
  //     respuesta = response.data.data;
  //   });

  return respuesta;
};

export const nombreEmpresa = "INVET";

// Production https://app-upro.com.ar/graphql
// Development http://MATEO:5000/graphql

// // //local
//const htprotocol = "http";
//const wsprotocol = "ws";
//const domain = "localhost:5000";

// production
const htprotocol = "https";
const wsprotocol = "wss";
const domain = "app-alianzainvet.com.ar";

export const API_URL = `${htprotocol}://${domain}`;

const GRAPHQL_URL = `${API_URL}/graphql`;
const WS_URL = `${wsprotocol}://${domain}/subscriptions`;

const httpLink = createUploadLink({
  uri: GRAPHQL_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_URL,
    connectionParams: () => {
      let token = "";
      if (localStorage.getItem("user")) token = JSON.parse(localStorage.getItem("user")).token;
      return {
        Authorization: token,
      };
    },
    on: {
      connected: () => console.log("Connected to GraphQL WS"),
      connecting: () => console.log("Connecting to GraphQL WS"),
      closed: () => console.log("Closed connection to GraphQL WS"),
      error: (error) => console.log("Error on GraphQL WS", error),
    },
  })
);

const authLink = setContext((_, { headers }) => {
  //Get the token from the user in local storage
  let token = "";
  if (localStorage.getItem("user")) token = JSON.parse(localStorage.getItem("user")).token;

  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});
