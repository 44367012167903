import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { useMutation, gql } from '@apollo/client';

// Custom Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

const SAVE_FRC_FORM = gql`
  mutation saveFrcForm($input: FrcFormInput!) {
    saveFrcForm(input: $input) {
      id
      profesional
      propietario
      contacto
      nombre
      pelaje
      idEspecie
      idRaza
      edad
      sexo
      idProvincia
      idLocalidad
      ecto_nroPulgas
      ecto_nroGarrapatas
      endo_idEspecie
      endo_hpg
      endo_formulacionRecibida
      endo_fechaTratamiento
      endo_fechaTratamiento1
      endo_fechaTratamiento2
      endo_tipo
      endo_dosis
      eog_peso
      eog_mucosas
      eog_linfodulos
      eog_temperatura
      eog_sensorio
      eog_frecuenciaRespiratoria
      eog_frecuenciaCardiaca
      eog_hidratacion
      eog_consumo
      eog_celo
      eog_fechaInicio
      eog_fechaAlta
      eog_obs
      mr_medicacionRecibida
      mr_antecedentes
      mr_signoDermatitis
      rea_trataConcomitante
      rea_altaClinica
      rea_observaciones
    }
  }
`;

export default function ExamenObjetivoGeneral({ proyecto, setProyecto, selectedEspecie }) {
  const [saveFrcForm] = useMutation(SAVE_FRC_FORM);

  const formatDate = (date) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? '' : parsedDate.toISOString().split('T')[0];
  };

  const handleChange = (field, value) => {
    setProyecto((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSelectChange = (field, value) => {
    setProyecto((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  
  const handleInputChange = (field, value) => {
    // Validar si el valor es numérico
    if (/^[0-9]+$/.test(value)) {
      handleChange(field, value);
    } 
    // Validar si es 'n' o 'N' y convertir a mayúscula si es necesario
    else if (value.toLowerCase() === 'n') {
      handleChange(field, 'N');
    }
    else {
      // Si no es ni un número ni 'n', se deja el valor sin cambios
      handleChange(field, value);
    }
  };
  

  useEffect(() => {
    if (selectedEspecie) {
      const defaults = selectedEspecie.nombre === "Canino" 
        ? {
            eog_mucosas: '< 2',
            eog_linfodulos: 0,
            eog_temperatura: '37.5 - 39.2',
            eog_frecuenciaRespiratoria: '10 - 30',
            eog_frecuenciaCardiaca: '60 - 180',
            eog_hidratacion: '< 2',
          }
        : {
            eog_mucosas: '< 2',
            eog_linfodulos: 0,
            eog_temperatura: '38 - 39.2',
            eog_frecuenciaRespiratoria: '20 - 42',
            eog_frecuenciaCardiaca: '140 - 220',
            eog_hidratacion: '< 1',
          };
      
      setProyecto(prev => ({ ...prev, ...defaults }));
    }
  }, [selectedEspecie, setProyecto]);

  const handleSave = async () => {
    const input = {
      profesional: proyecto.profesional || '',
      propietario: proyecto.propietario || '',
      contacto: proyecto.contacto || '',
      nombre: proyecto.nombre || '',
      pelaje: proyecto.pelaje || '',
      idEspecie: proyecto.idEspecie || null,
      idRaza: proyecto.idRaza || null,
      edad: proyecto.edad || null,
      sexo: proyecto.sexo || '',
      idProvincia: proyecto.idProvincia || null,
      idLocalidad: proyecto.idLocalidad || null,
      ecto_nroPulgas: Number(proyecto.pulgas) || 0,
      ecto_nroGarrapatas: Number(proyecto.garrapatas) || 0,
      endo_idEspecie: proyecto.endo_idEspecie || null,
      endo_hpg: Number(proyecto.valorHPG) || 0,
      endo_formulacionRecibida: proyecto.formulacionRecibida || '',
      endo_fechaTratamiento: formatDate(proyecto.fechaTratamiento),
      endo_fechaTratamiento1: formatDate(proyecto.extraFecha1),
      endo_fechaTratamiento2: formatDate(proyecto.extraFecha2),
      endo_tipo: proyecto.endo_tipo || '',
      endo_dosis: proyecto.endo_dosis || '',
      eog_peso: parseFloat(proyecto.eog_peso || null),
      eog_mucosas: proyecto.eog_mucosas || '',
      eog_linfodulos: proyecto.eog_linfodulos || '',
      eog_temperatura: parseFloat(proyecto.eog_temperatura || null),
      eog_sensorio: proyecto.eog_sensorio || '',
      eog_frecuenciaRespiratoria: parseFloat(proyecto.eog_frecuenciaRespiratoria || null),
      eog_frecuenciaCardiaca: parseFloat(proyecto.eog_frecuenciaCardiaca || null),
      eog_hidratacion: proyecto.eog_hidratacion || '',
      eog_consumo: proyecto.eog_consumo || '',
      eog_celo: proyecto.eog_celo || '',
      eog_fechaInicio: formatDate(proyecto.eog_fechaInicio),
      eog_fechaAlta: formatDate(proyecto.eog_fechaAlta),
      eog_obs: proyecto.eog_obs || '',
      mr_medicacionRecibida: proyecto.mr_medicacionRecibida || '',
      mr_antecedentes: proyecto.mr_antecedentes || '',
      mr_signoDermatitis: proyecto.mr_signoDermatitis || '',
      rea_trataConcomitante: proyecto.rea_trataConcomitante || '',
      rea_altaClinica: proyecto.rea_altaClinica || '',
      rea_observaciones: proyecto.rea_observaciones || '',
    };

    const sanitizedInput = omit(input, '__typename');
    console.log("Datos a guardar:", input);

    try {
      const { data } = await saveFrcForm({ variables: { input } });
      console.log("Formulario guardado:", data.saveFrcForm);
    } catch (error) {
      console.error("Error al guardar el formulario:", error);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message }) => {
          console.error(message);
        });
      }
      alert("Error al guardar los datos, por favor intente nuevamente.");
    }
  };

  return (
    <SoftBox>
      <SoftTypography variant="h6" fontWeight="bold">Examen objetivo general</SoftTypography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_peso">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Peso (kg)
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="eog_peso"
                type="number"
                value={proyecto?.eog_peso || ''}
                onChange={(e) => handleInputChange("eog_peso", isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value))}
                placeholder="Kg."
                fullWidth
              />
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="mucosas">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Mucosas tllc *
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="mucosas"
                type="text"
                value={proyecto?.eog_mucosas || 'N'}  
                onChange={(e) => {
                  // Validación para permitir solo números o "N"
                  const value = e.target.value;
                  if (/^[0-9]*$/.test(value) || value === 'N') {
                    handleInputChange("eog_mucosas", value);
                  }
                }}
                placeholder="Seg."
                fullWidth
              />
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_linfodulos">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Linfonodulos *
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="eog_linfodulos"
                type="text"
                value={proyecto?.eog_linfodulos || 'N'}  
                onChange={(e) => {
                  // Validación para permitir solo números o "N"
                  const value = e.target.value;
                  if (/^[0-9]*$/.test(value) || value === 'N') {
                    handleInputChange("eog_linfodulos", value);
                  }
                }}
                placeholder="-"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_temperatura">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Temperatura *
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="eog_temperatura"
                type="text"  // Cambiado de "number" a "text" para permitir "N"
                value={proyecto?.eog_temperatura || 'N'}  // Valor por defecto "N"
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[0-9]*$/.test(value) || value === 'N') {
                    handleInputChange("eog_temperatura", value);
                  }
                }}
                placeholder="° C"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_sensorio">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Sensorio/ fascie *
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="sensorio"
                type="text"
                value={proyecto?.eog_sensorio || 'N'}
                onChange={(e) => {
                  // Validación para permitir solo números o "N"
                  const value = e.target.value;
                  if (/^[0-9]*$/.test(value) || value === 'N') {
                    handleInputChange("eog_sensorio", value);
                  }
                }}
                placeholder="-"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_frecuenciaRespiratoria">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Frec. Respiratoria *
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="eog_frecuenciaRespiratoria"
                type="text"  // Cambiar de "number" a "text" para permitir "N"
                value={proyecto?.eog_frecuenciaRespiratoria || 'N'}  // Valor por defecto "N"
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[0-9]*$/.test(value) || value === 'N') {
                    handleInputChange("eog_frecuenciaRespiratoria", value);
                  }
                }}
                placeholder="Resp./min"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_frecuenciaCardiaca">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Frec. Cardíaca *
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="eog_frecuenciaCardiaca"
                type="number"
                value={proyecto?.eog_frecuenciaCardiaca || ''}
                onChange={(e) => handleInputChange("eog_frecuenciaCardiaca", isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value))}
                placeholder="Lat/min"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_hidratacion">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Hidratación
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="eog_hidratacion"
                type="text"
                value={proyecto?.eog_hidratacion || 'N'}  
                onChange={(e) => {
                  // Validación para permitir solo números o "N"
                  const value = e.target.value;
                  if (/^[0-9]*$/.test(value) || value === 'N') {
                    handleInputChange("eog_hidratacion", value);
                  }
                }}
                placeholder="Seg."
                fullWidth
              />
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_consumo">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Consumo (agua/alimento) *
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="eog_consumo"
                type="text"
                value={proyecto?.eog_consumo || 'N'}  
                onChange={(e) => {
                  // Validación para permitir solo números o "N"
                  const value = e.target.value;
                  if (/^[0-9]*$/.test(value) || value === 'N') {
                    handleInputChange("eog_consumo", value);
                  }
                }}
                placeholder="-"
                fullWidth
              />
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_celo">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Celo/Gestación *
                </SoftTypography>
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  id="eog_celo"
                  value={proyecto?.eog_celo || 'NA'}  
                  onChange={(e) => handleSelectChange("eog_celo", e.target.value)}
                >
                  <MenuItem value="Celo">Celo</MenuItem>
                  <MenuItem value="Gestación">Gestación</MenuItem>
                  <MenuItem value="NA">NA (No aplica)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_fechaInicio">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Fecha Inicio *
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="eog_fechaInicio"
                type="date"
                value={proyecto?.eog_fechaInicio || ''}
                onChange={(e) => handleChange("eog_fechaInicio", e.target.value)}
                fullWidth
              />
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="eog_fechaAlta">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Fecha Alta
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="eog_fechaAlta"
                type="date"
                value={proyecto?.eog_fechaAlta || ''}
                onChange={(e) => handleChange("eog_fechaAlta", e.target.value)}
                fullWidth
              />
            </Grid>
  
            <Grid item xs={12}>
              <InputLabel htmlFor="eog_obs">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Observaciones
                </SoftTypography>
              </InputLabel>
              <SoftInput
                id="eog_obs"
                type="text"
                value={proyecto?.eog_obs || ''}
                onChange={(e) => handleChange("eog_obs", e.target.value)}
                placeholder="Especificar observaciones"
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SoftBox>
  );
  
}

ExamenObjetivoGeneral.propTypes = {
  proyecto: PropTypes.object,
  setProyecto: PropTypes.func,
  selectedEspecie: PropTypes.object.isRequired,
};
